<template>
  <div class="pageContol UnitEdit">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">老师管理（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">老师姓名</a>
          <i>></i>
          <a href="javascript:;" class="cur-a"
            >{{ unitJson.stu == "add" ? "新增" : "修改" }}老师</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box">
              <el-form
                ref="ruleForm"
                label-width="8rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
              >
                <el-form-item label="老师名称" prop="teacherName">
                  <el-input
                    v-model="ruleForm.teacherName"
                    placeholder="请输入老师名称"
                    size="small"
                    maxlength="50"
                    clearable
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="mobile">
                  <el-input
                    v-model="ruleForm.mobile"
                    size="small"
                    placeholder="请输入联系方式"
                    clearable
                  ></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="sex">
                  <el-select
                    v-model="ruleForm.sex"
                    clearable
                    filterable
                    placeholder="请选择性别"
                    size="small"
                  >
                    <el-option
                      size="small"
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item label="教龄" prop="teachingAge">
                  <el-input-number
                    v-model="ruleForm.teachingAge"
                    @change="handleChange"
                    min="0"
                    max="80"
                    :step-strictly="true"
                    size="small"
                  ></el-input-number>
                </el-form-item> -->
                <el-form-item label="管理课程" prop="miniCourses">
                  <el-select
                    v-model="ruleForm.miniCourses"
                    size="small"
                    multiple
                    filterable
                    placeholder="请选择管理课程"
                  >
                    <el-option
                      v-for="item in miniCoursesoptions"
                      :key="item.courseId"
                      :label="item.courseName"
                      :value="item.courseId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="关于老师" prop="remark">
                  <el-input
                    v-model="ruleForm.remark"
                    clearable
                    size="small"
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="btn-box flexcc">
              <el-button @click="doCancel" class="bgc-bv">取 消</el-button>
              <el-button class="bgc-bv" @click="doAddSave">确 定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "UnitEdit",
  components: {},
  data() {
    var Phone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入联系人电话"));
      } else {
        callback();
      }
    };
    return {
      stu: "add",
      // 基本信息
      ruleForm: {
        teacherName: "",
        mobile: "",
        sex: "",
        teachingAge: "",
        remark: "",
        miniCourses: [],
      },
      rules: {
        teacherName: [
          { required: true, trigger: "blur", message: "请输入老师姓名" },
        ],
        sex: [{ required: true, trigger: "change", message: "请选择性别" }],
        mobile: [{ required: true, trigger: "blur", validator: Phone }],
      },
      options: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "2",
          label: "女",
        },
      ],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      miniCoursesoptions: [],
    };
  },
  created() {
    this.unitJson = this.$route.query;

    // if(this.unitJson.stu == "addsubordinate") {
    //   this.ruleForm.parentCompId = this.unitJson.compId,
    //   this.getCompany(this.ruleForm.parentCompId)

    // }
    if (this.unitJson.stu == "edit") {
      this.queryData(this.unitJson.teacherId);
    }
  },
  mounted() {
    this.getCourseList();
  },
  computed: {},
  methods: {
    //课程列表
    getCourseList() {
      this.$post("/minicourse/list", {}, 3000, true, 2)
        .then((ret) => {
          this.miniCoursesoptions = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    // // 编辑保存
    doAddSave() {
      const params = {
        teacherName: this.ruleForm.teacherName,
        mobile: this.ruleForm.mobile,
        sex: this.ruleForm.sex,
      };
      if (this.ruleForm.teachingAge) {
        params.teachingAge = this.ruleForm.teachingAge;
      }
      if (this.ruleForm.remark) {
        params.remark = this.ruleForm.remark;
      }
      if (this.ruleForm.miniCourses.length > 0) {
        params.miniCourses = this.ruleForm.miniCourses;
      }
      if (this.unitJson.stu == "edit") {
        if (this.unitJson.teacherId) {
          params.teacherId = this.unitJson.teacherId;
        }
      }
      const el = this.$refs["ruleForm"];
      el.validate((valid) => {
        if (valid) {
          this.$post(
            this.unitJson.stu == "add"
              ? "/teacher/mini-teacher/insert"
              : "/teacher/mini-teacher/modify",
            params,
            3000,
            true,
            2
          )
            .then((res) => {
              if (res.status == 0) {
                this.$message({
                  message: this.unitJson.stu == "add" ? "新增成功" : "修改成功",
                  type: "success",
                });
                // setTimeout(() => {
                this.doCancel();
                // }, 3000);
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    // 编辑回显查询
    queryData(teacherId) {
      this.$post("/teacher/mini-teacher/getInfo", { teacherId }, 3000, true, 2)
        .then((res) => {
          const data = res.data || {};
          this.ruleForm = {
            ...data,
          };
        })
        .catch((err) => {
          return;
        });
    },
    /* 取消 */
    doCancel() {
      this.$router.push({
        path: "/web/miniApp/teacherList",
        query: {
          refresh: true,
        },
      });
    },
  },
};
</script>
<style lang="less">
.UnitEdit {
  .el-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    .el-form-item {
      width: 100%;
      margin-bottom: 22px;
    }
  }
}
.el-textarea__inner {
  min-height: 100px !important;
  resize: none;
}
.el-input-number {
  width: 100%;
}
</style>
